import React from 'react';

export default function Box({ boxNumber, box, row, onClick }) {
  const EMPTY_CLASS = 'empty';
  return (
    <div onClick={onClick} className={!boxNumber ? EMPTY_CLASS : null} id={`row-${row}-box-${box}`}>
      <img src={require(`./imgs/${boxNumber}.png`)}/>
    </div>
  );
}


import React, { useState } from 'react';
import Box from './Box';
import BoardLogic from './BoardLogic';

const INITIAL_STATE = {
  board: [
    [1, 2, 3, 4],
    [5, 6, 7, 8],
    [9, 10, 11, 12],
    [13, 14, 15, 0],
  ],
  isWin: false,
  moves: 0,
  notStarted: true,
};

const Board = ({ size = 4 }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const [boardLogic, setBoardLogic] = useState(null);

  const newGameState = () => {
    const board = new BoardLogic(size);
    console.log(board);
    setBoardLogic(board);
    return {
      board: board.scramble(),
      moves: 0,
      isWin: board.checkWin(),
    };
  };

  const move = (i, j) => {
      if (state.notStarted) return;
    if (state.isWin) return;
    if (boardLogic.move(i, j)) {
      setState(prevState => ({
        board: boardLogic.matrix,
        moves: prevState.moves + 1,
        isWin: boardLogic.checkWin(),
      }));
    }
  };

  const getRow = (rowData, j) => {
    return (
      <div key={j}>
        {rowData.map((bNum, i) => (
          <Box key={bNum} row={j+1} box={i+1} boxNumber={bNum} onClick={() => move(i, j)} />
        ))}
      </div>
    );
  };

  const newGame = () => {
    setState(newGameState());
  };

  let rows = state && state.board.map(getRow);
  let message = state && (state.isWin ? 'Winner !!! ' : 'Total ') + `Moves: ${state.moves}`;
  return (
    <div>
      <div className="slider-board">
        {rows}
      </div>
      <span className="slider-msg">{message}</span>
      <div className="btn-new-game">
        <button onClick={newGame}>New Game</button>
      </div>
    </div>
  );
};

export default Board;
